.pop-up-card{
    z-index: 1;
    background-color: rgb(98, 35, 161);
    color: whitesmoke;
    height: 60vh;
    width: 30vw;
    position: fixed;
    top: 20%; 
    left: 35%;   
    border-radius: 10px;
    padding: 40px 20px 0 20px;
    font-size: 1.3rem;
    font-family: inter;
}

.pop-up-info{
    padding-top: 5vh;
}

.popup-redirect{
    color: brown;
}



.cancel{
    float: right;
    cursor: pointer;
}




@media screen and (max-width:520px) {
    .pop-up-card{
        width: 65%;
        left: 20%;
        font-size: 1.1rem;
    }
}